/* 1. Main Container */
.chat-container {
    position: relative;
    min-height: 100vh;
    padding: 1rem;
    transition: background-color 0.3s ease;
}

/* 2. Header */
.chat-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
}

.chat-header img {
    transition: transform 0.3s ease;
}

.chat-header img:hover {
    transform: scale(1.1);
}

/* 3. History Panel */
.history-panel {
    position: fixed;
    left: 0;
    top: 75px;
    height: calc(100vh - 100px);
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
    border-radius: 0 8px 8px 0;
    transition: all 0.3s ease;
    z-index: 10;
    width: 300px;
    overflow-y: auto;
}

.light-mode .history-panel {
    background-color: var(--chakra-colors-gray-50);
    border-right: 1px solid var(--chakra-colors-gray-200);
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .history-panel {
    background-color: var(--chakra-colors-gray-700);
    border-right: 1px solid var(--chakra-colors-gray-600);
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.3);
}

.history-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    padding-right: 20px;
}

.history-panel:not(.open) {
    width: 50px;
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
}

.history-panel:not(.open) .history-panel-content {
    display: none;
}

.history-toggle-button {
    position: absolute !important;
    right: -12px !important;
    top: 0;
    width: 24px !important;
    height: 24px !important;
    border-radius: 20% !important;
    z-index: 11;
    display: flex !important;
}

.light-mode .history-toggle-button {
    background-color: var(--chakra-colors-gray-100) !important;
    color: var(--chakra-colors-gray-800) !important;
    border: 1px solid var(--chakra-colors-gray-200);
}

.dark-mode .history-toggle-button {
    background-color: var(--chakra-colors-gray-600) !important;
    color: var(--chakra-colors-gray-100) !important;
    border: 1px solid var(--chakra-colors-gray-500);
}

.chat-message {
    padding: 0.75rem;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    transition: all 0.2s ease;
}

.light-mode .chat-message {
    background-color: var(--chakra-colors-gray-100);
    color: var(--chakra-colors-gray-800);
}

.dark-mode .chat-message {
    background-color: var(--chakra-colors-gray-600);
    color: var(--chakra-colors-gray-100);
}

/* 4. Main Chat Area */
.main-chat-area {
    margin-left: 110px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    height: calc(100vh - 120px);
    transition: margin-left 0.3s ease;
}

.history-panel:not(.open)+.main-chat-area {
    margin-left: 60px;
}

/* 5. Model Selection */
.model-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
}

.interface-selection {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
}

.model-option {
    padding: 0.5rem;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* 6. Response Container */
.response-container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    background-color: inherit;
    height: 400px;
    min-height: 300px;
    max-height: 400px;
}

.response-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    height: calc(100% - 60px);
}

.response-message {
    padding: 0.75rem;
    border-radius: 8px;
    margin-bottom: 0.75rem;
    transition: all 0.2s ease;
}

/* 7. Chat Input Area */
.chat-input-container {
    padding: 0.75rem;
    border-radius: 8px;
    margin-top: 0.75rem;
}

.chat-input-wrapper {
    display: flex;
    gap: 0.75rem;
    align-items: flex-start;
}

/* 8. Attachments */
.attachments-list {
    margin-top: 0.75rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.attachment-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    background-color: rgba(128, 128, 128, 0.1);
}

/* 9. Buttons */
.attach-button,
.send-button {
    transition: transform 0.2s ease !important;
}

.attach-button:hover,
.send-button:hover {
    transform: translateY(-2px);
}

.clear-history-button {
    width: 100%;
    margin-top: 1rem !important;
    background-color: var(--chakra-colors-red-500) !important;
    color: white !important;
}

.clear-history-button:hover {
    background-color: var(--chakra-colors-red-600) !important;
    transform: translateY(-1px);
}

/* 10. Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.3);
    border-radius: 3px;
}

/* 11. History Title */
.history-title {
    font-size: 1.1rem;
    font-weight: bold;
}

.light-mode .history-title {
    color: var(--chakra-colors-gray-800);
}

.dark-mode .history-title {
    color: var(--chakra-colors-gray-100);
}

/* 12. Responsive Design */
@media (max-width: 992px) {
    .history-panel {
        width: 280px;
    }

    .main-chat-area {
        margin-left: 290px;
    }

    .model-selection {
        gap: 0.5rem;
    }
}

@media (max-width: 768px) {
    .history-panel {
        width: 260px;
    }

    .main-chat-area {
        margin-left: 270px;
    }

    .response-container {
        height: 350px;
        min-height: 250px;
    }

    .chat-input-wrapper {
        gap: 0.5rem;
    }
}

@media (max-width: 480px) {
    .chat-container {
        padding: 0.5rem;
    }

    .chat-header {
        flex-direction: column;
    }

    .history-panel {
        width: 100% !important;
        transform: translateX(-100%);
    }

    .history-panel.open {
        transform: translateX(0);
    }

    .history-panel:not(.open) {
        width: 50px !important;
    }

    .history-toggle-button {
        right: -15px !important;
    }

    .main-chat-area {
        margin-left: 0;
    }

    .model-selection {
        justify-content: space-around;
    }

    .response-container {
        height: 300px;
        min-height: 200px;
    }

    .chat-input-wrapper {
        flex-direction: column;
    }

    .chat-input-wrapper>* {
        width: 100%;
    }
}

/* 13. Focus States */
.attach-button:focus,
.send-button:focus,
.history-toggle-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px currentColor;
}