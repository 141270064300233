.media-content {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: var(--largest-width);
    margin: 0 auto;
    overflow: hidden;
}

.media-content img {
    border-radius: 8px;
    /* Optional: Add rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    /* Optional: Add shadow */
    max-width: 100%;
    height: auto;
}