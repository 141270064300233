/* 1. General Layout */
.landing-page {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 100vh;
    padding: 10px;
    position: relative;
}

/* 2. Theme Toggle & Spline Container */
.theme-toggle {
    position: absolute;
    top: 10px;
    right: 40px;
    z-index: 10;
}

.spline-container {
    position: absolute;
    top: 10px;
    right: 40px;
    width: 30vw;
    height: 40vh;
    padding: 10px;
}

/* 3. Arrow Structure */
.arrow-structure {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 70px;
}

.horizontal-line {
    display: flex;
    justify-content: space-between;
    width: 400px;
    height: 2px;
    margin-top: -20px;
    position: relative;
    background-color: currentColor;
}

.horizontal-line::before {
    content: "";
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 40px;
    background-color: currentColor;
}

.arrow-branch {
    position: absolute;
    width: 2px;
    height: 50px;
    top: 100%;
    background-color: currentColor;
}

.arrow-branch::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
}

.arrow-visualize {
    left: 0;
}

.arrow-ai-tools {
    left: 50%;
    transform: translateX(-50%);
}

.arrow-receive {
    right: 0;
}

/* 4. Main Icons */
.icon-tiles-container {
    display: flex;
    justify-content: center;
    gap: 100px;
    width: fit-content;
    margin: 140px auto 0;
    position: relative;
    max-width: 600px;
    padding: 0 10px;
}

.icon-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 16px;
    padding: 10px;
    backdrop-filter: blur(8px);
}

.icon {
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin-bottom: 10px;
    border-radius: 8px;
    object-fit: contain;
}

/* 5. Selected Content */
.selected-content {
    position: absolute;
    top: 30px;
    left: 10px;
    padding: 20px;
    border-radius: 8px;
    background-color: transparent;
}

.selected-icon-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.selected-additional-icon {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
    margin-left: 25px;
    padding-top: 10px;
}

.selected-main-icon {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.selected-icon {
    width: 75px;
    height: 75px;
    object-fit: contain;
}

.icon-label-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.additional-icon-tile .icon-label {
    display: flex;
    align-items: center;
    gap: 8px;
}

.additional-icon-tile .chakra-icon {
    margin-left: 1px;
}
/* 6. Additional Icons */
.additional-icons-container {
    margin-top: 50px;
    padding-left: 50px;
    width: 200px;
}

.additional-icon-tile {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    width: 200px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 20px;
}

.additional-icon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    margin-right: 15px;
    object-fit: contain;
}

/* 7. Theme-Specific Styles */
.light-mode .icon-tile:hover,
.light-mode .icon-tile.selected,
.light-mode .additional-icon-tile.selected,
.light-mode .additional-icon-tile.hovered {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .icon-tile:hover,
.dark-mode .icon-tile.selected,
.dark-mode .additional-icon-tile.selected,
.dark-mode .additional-icon-tile.hovered {
    box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.6);
    filter: brightness(1.2);
    transform: translateY(-2px);
    background-color: rgba(0, 255, 0, 0.05);
}

/* 8. Typewriter & Button */
.typewriter-container {
    margin-top: 10px;
    border-radius: 8px;
    width: 35vw;
    max-width: 350px;
    min-height: 100px;
    height: auto;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    padding: 15px;
    padding-bottom: 60px;
}

.light-mode .typewriter-container {
    background-color: #EDF2F7;
    color: #000000;
}

.dark-mode .typewriter-container {
    background-color: #2D3748;
    color: #FFFFFF;
}

.typewriter-text {
    margin-bottom: 30px;
}

.explore-button {
    position: absolute !important;
    bottom: 15px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 100px !important;
    height: 36px !important;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.light-mode .explore-button {
    background-color: #4299E1 !important;
    color: white !important;
}

.dark-mode .explore-button {
    background-color: #319795 !important;
    color: white !important;
}

/* 9. Utilities */
.icon-label {
    font-size: 0.875rem;
    font-weight: bold;
    color: inherit;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 10. Responsive Design */
@media (max-width: 1200px) {

    .spline-container,
    .theme-toggle {
        right: 30px;
    }

    .horizontal-line {
        width: 320px;
    }

    .icon-tiles-container {
        gap: 80px;
        margin-top: 120px;
    }

    .typewriter-container {
        width: 45vw;
    }
}

@media (max-width: 768px) {
    .landing-page {
        flex-direction: column;
        align-items: center;
    }

    .spline-container {
        width: 100vw;
        height: 30vh;
        position: relative;
        top: 0;
        right: 0;
    }

    .horizontal-line {
        width: 280px;
    }

    .icon-tiles-container {
        gap: 60px;
        margin-top: 100px;
    }

    .selected-content {
        position: relative;
        width: 100%;
        padding: 15px;
    }

    .selected-additional-icon {
        margin-left: 20px;
    }

    .typewriter-container {
        width: 90vw;
        max-width: none;
    }

    .additional-icons-container {
        padding-left: 20px;
        width: 180px;
    }
}

@media (max-width: 480px) {
    .horizontal-line {
        width: 240px;
    }

    .icon-tiles-container {
        gap: 40px;
        margin-top: 80px;
    }

    .icon-tile,
    .icon,
    .selected-main-icon {
        width: 80px;
        height: 80px;
    }

    .selected-icon {
        width: 60px;
        height: 60px;
    }

    .selected-additional-icon {
        margin-left: 15px;
    }

    .additional-icon-tile {
        width: 160px;
    }
}